<template>
	<div id="ap">
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="6">
							<common-page-btn @apply="$refs.recordForm._show()">
								<a-button type="primary" @click="Exportex">导出报表</a-button></common-page-btn>
						</a-col>
						<a-col span="18">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="8">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getList" @on-clear="getList"
													 placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="5">
									<a-month-picker @clear="getList" @change="getList" v-model="param.date" placeholder="请选择月份" />
								</a-col>
								<a-col span="8">
									<select-car multiple v-model="param.carIds" @confirm="(e, c) => param.carIds = c"
															@success="getList()"></select-car>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-layout>
			<a-layout-content>
				<a-table :defaultExpandAllRows="true" :scroll="{ y: 800 }" :pagination="false" :indentSize="25"
								 :style="{ background: '#FFF' }" size="small" row-key="applyId" border :columns="keys" ref="list"
								 :loading="loading" :data-source="data.records">
					<!-- 状态 -->
					<template slot-scope="row" slot="status">
						<a-tooltip title="待审批">
							<a-icon v-if="row.status == '待审批'" type="question-circle" />
						</a-tooltip>
						<a-tooltip :title="`未批准：${row.cause}`">
							<a-icon v-if="row.status == '未批准'" style="color:red" type="stop" />
						</a-tooltip>
						<a-tooltip title="已批准"><a-icon style="color:#090" v-if="row.status == '已批准'"
											type="check-circle" /></a-tooltip>
					</template>
					<!--操作-->
					<template slot-scope="row" slot="action">
						<a-button-group shape="circle" size="small">
							<a-tooltip v-if="row.status == '待审批'" title="审批"><a-button type="link" v-has="'car:record:audit'"
													icon="check" @click="showModal(row)"></a-button></a-tooltip>
						</a-button-group>
					</template>
				</a-table>
			</a-layout-content>
		</a-layout>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit"
											show-size-changer :pageSizeOptions="pageSizeOptions" :current="data.current">
					<template slot="buildOptionText" slot-scope="props">
						<span>{{ props.value }}条/页</span>
					</template>
				</a-pagination>
			</a-col>
		</a-row>

		<record-form ref="recordForm" @success="doRefresh"></record-form>
		<div>
			<a-modal title="审批" cancelText="取消审批" okText="确认审批" :visible="visible" :confirm-loading="confirmLoading"
							 @ok="handleOk" @cancel="handleCancel">
				<a-form-model>
					<a-row :gutter="15">
						<a-col span="8">
							<a-form-model-item label="审批结果" label-position="top">
								<a-switch v-model="isExamine" checked-children="通过" un-checked-children="不通过" />
							</a-form-model-item>
						</a-col>
						<a-col span="16">
							<a-form-model-item label="不通过原因" label-position="top" :required="!isExamine">
								<a-input v-model="cause" :disabled="isExamine" placeholder="请输入不通过原因"></a-input>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
			</a-modal>
		</div>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import moment from 'moment';
import recordForm from './components/recordForm.vue';
import selectCar from './components/selectCar.vue';
import ExporttoExcel from "@/common/ExportExcel.js";

export default {
	components: { recordForm, selectCar },
	data() {
		return {
			visible: false,
			confirmLoading: false,
			loading: false,
			data: [],
			isExamine: false,
			cause: '',
			applyId: null,
			organList: apiUtil.getOrganList(),
			depList: [],
			pageSizeOptions: ['10', '20', '30', '50', '100'],
			param: {
				page: 1,
				limit: 20,
				organId: apiUtil.getOrganId(''),
				keyword: '',
				date: null,
				carIds: []
			},
			keys: [
				{ title: '车牌号', dataIndex: 'carNumber', width: 200, ellipsis: true, align: 'center' },
				{ title: '用车人', dataIndex: 'name', width: 160, ellipsis: true },
				{ title: '申请原因', dataIndex: 'content', ellipsis: true },
				{ title: '申请时间', dataIndex: 'createTime', width: 160, align: 'center', ellipsis: true },
				{ title: '调度人', dataIndex: 'assignName', width: 160, align: 'center', ellipsis: true },
				{ title: '状态', scopedSlots: { customRender: 'status' }, align: 'center', width: 100, ellipsis: true },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 200 }
			]
		};
	},

	created() {
		this.getList();
	},
	methods: {
		...apiUtil,
		doRefresh() {
			// console.log('成功');
			this.param.page = 1;
			this.getList();
		},
		// 获取数据
		getList() {
			this.loading = true;
			if (this.param.date && this.param.date != '') {
				this.param.date = this.param.date.format('YYYY-MM');
			}
			this.http
				.get('/platform/car/record/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 导出报表
		Exportex() {
			if (this.data.records && this.data.records.length > 0) {
				let date = '';
				if (this.param.date && this.param.date != '') {
					date = this.param.date;
				} else {
					date = '全部';
				}
				const filterData = this.data.records.map((item) => {
					return { '车牌号': item.carNumber, "用车人": item.name, "申请原因": item.content, "申请时间": item.createTime, "调度人": item.assignName, "状态": item.status, };
				});
				ExporttoExcel(filterData, `${date}申请记录表.xlsx`);
			} else {
				this.$message.info('没有数据哦！！!');
				return;
			}
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		},
		// 点击审批
		showModal(e) {
			// console.log(e);
			this.applyId = e.applyId;
			this.cause = '';
			if (e.cause == '待审批' || e.cause == '未批准') {
				this.isExamine = false;
			} else {
				this.isExamine = true;
			}
			this.visible = true;
		},
		// 确认审批
		handleOk(e) {
			this.http
				.post('/platform/car/record/audit', { applyId: this.applyId, isAgree: this.isExamine, cause: this.cause })
				.then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getList();
						});
					}
					this.visible = false;
				})
				.catch(err => {
					this.visible = false;
				});
		},
		// 取消审批
		handleCancel(e) {
			this.visible = false;
		}
	}
};
</script>

<style scoped></style>
